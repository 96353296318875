
















































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  MetaModule as metaStore,
  FAQModule as faqStore,
  FAQModule
} from "@/store/modules";
import { FAQ } from "@/store/models/faq";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination
  }
})
export default class FAQClass extends Vue {
  data: FAQ[] = [];
  qData: any[] = [];
  filter = {
    question: ""
  };
  async created() {
    await faqStore.loadAllFAQ();
    //await faqStore.loadAllFAQ(this.filter);
    this.data = faqStore.faqList;
  }
  get AdminRouter() {
    return AdminRouter;
  }
  handleClose(id: any) {
    this.$confirm(`Are you sure to Delete?`)
      .then(_ => {
        faqStore.deleteFAQ(id);
        this.$snotify.success("FAQ  Deleted Successfully");
      })
      .catch(_ => {});
  }
  async search(){
   var filterData = faqStore.faqList;
   filterData = filterData.filter(x=>x.Question.toLowerCase().includes(this.filter.question));
   this.data = filterData;
  }
  clear(){
    this.filter={
      question:""
    };
    this.data = faqStore.faqList;
  }
}
